import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {string?} type
 * @param {string?} clientId
 * @param {string?} employeeId
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getInvoices = (type, isManager, clientId, employeeId, invoiceIds = [], placementIds=[],invoicedAmounts=[],skip = 0, take = 0, signal) => {
	const params = new URLSearchParams();
	if (type) params.append("type", type);
	if (isManager) params.append("isManager", isManager);
	if (clientId) params.append("clientId", clientId);
	if (employeeId) params.append("employeeId", employeeId);
	if (invoiceIds.length) params.append("invoiceIds", invoiceIds);
	if (placementIds.length) params.append("placementIds", placementIds);
	if (invoicedAmounts.length) params.append("invoicedAmounts", invoicedAmounts);
	if (skip) params.append("skip", skip);
	if (take) params.append("take", take);

	return defaultInstance({
		method: "GET",
		url: `/invoices/getAll?${params.toString()}`,
		signal,
	});
};

export const createTag = (encodedID,isUpdatingFollowup,followupComment,tagType,signal) => {
	return defaultInstance({
	method: "PUT",
	url: `/invoices/followup?invoiceID=${encodedID}&isUpdatingFollowup=${isUpdatingFollowup}`,
    data: {
        followupComment,
        tagType,
    },
    signal,
	});
};
/**
 * @returns {Promise<Array<Record>>}
*/
export const getInvoicesDefaulters = ({
	clientID = "",
	employeeID = "",
}) => {
	return defaultInstance({
		method: "GET",
		url: `/invoices/getInvoicesDefaulters?clientID=${clientID}&employeeID=${employeeID}`,
	});
}

export const getTagsData = () => {
	console.log('call done')
	return defaultInstance({
		method: "GET",
		url: `/invoices/tagsData`,
	});
};

export const getTagsTable = (branch,signal) => {
	console.log('branch call done')
	return defaultInstance({
		method: "GET",
		url: `/invoices/tagsTableData?branch=${branch}`,
		signal,
	});
};

export const getInvoiceIds = (type, isManager, clientId, employeeId, queryKey = 'id') => {
	const params = new URLSearchParams();
	if (type) params.append("type", type);
	if (isManager) params.append("isManager", isManager);
	if (clientId) params.append("clientId", clientId);
	if (employeeId) params.append("employeeId", employeeId);
	if (queryKey) params.append("queryKey", queryKey);

	return defaultInstance({
		method: "GET",
		url: `/invoices/ids?${params.toString()}`,
	});
};

export const exportInvoices = (type, clientId, employeeId, signal) => {
	const params = new URLSearchParams();
	if (type) params.append("type", type);
	if (clientId) params.append("clientId", clientId);
	if (employeeId) params.append("employeeId", employeeId);

	return defaultInstance({
		method: "GET",
		url: `/invoices/export?${params.toString()}`,
		responseType: "blob",
		signal,
	});
};